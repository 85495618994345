export enum LotteryScope {
  All = "all",
  My = "my",
  Active = "active",
  Upcoming = "upcoming",
  Finished = "finished",
  NotFinished = "not_finished",
}

export enum LotteryType {
  DailyFree = "daily_free",
  DailyPaid = "daily_paid",
  WeeklyPaid = "weekly_paid",
  HourlyPaid = "hourly_free",
}

export interface LotteriesIndexPayload {
  scopes: LotteryScope[];
  offset: number;
  limit: number;
}

export enum LotteryStatus {
  Active = "active",
  Finished = "finished",
  Upcoming = "upcoming",
}

export interface Lottery {
  id: string;
  title: string;
  endsAt: number;
  startsAt: number;
  imageUrl: string;
  prizeFund: number;
  type: LotteryType;
  status: LotteryStatus;
}

export interface LotteriesIndexResponse {
  lotteries: Lottery[];
}
