import { RouteObject } from "react-router-dom";

type Loader = Promise<() => JSX.Element | null>;

const loaders: Record<string, Loader> = {};

function createLoader(path: string, loader: () => Loader) {
  return async () => {
    console.log(`Lazy loading ${path}`);

    if (!loaders[path]) {
      console.log(`Initialize loader for ${path}`);
      loaders[path] = loader();
    } else {
      console.log(`Using existed loader for ${path}`);
    }

    return { Component: await loaders[path] };
  };
}

export enum RoutePaths {
  Home = "/",
  Onboarding = "/onboarding",
  OnboardingIndex = "/onboarding/index",
  OnboardingStep1 = "/onboarding/step1",
  OnboardingStep2 = "/onboarding/step2",
  OnboardingStep3 = "/onboarding/step3",
  OnboardingStep4 = "/onboarding/step4",
  OnboardingStep5 = "/onboarding/step5",
  OnboardingStep6 = "/onboarding/step6",
  OnboardingStep7 = "/onboarding/step7",
  Game = "/game",
  GameIndex = "/game/index",
  GameCityBuilder = "/game/city-builder",
  GameMoves = "/game/moves",
  GameMovesSingle = "/game/moves/single",
  GameMovesClaimable = "/game/moves/claimable",
  GameChamps = "/game/champs",
  GameEarn = "/game/earn",
  GameLottery = "/game/lottery",
  GameAirdrop = "/game/airdrop",
  GameSettings = "/game/settings",
  GameSettingsLanguage = "/game/settings/language",
  GameBoosters = "/game/boosters",
  GameSkins = "/game/skins",
  NotFound = "*",
}

export const routes: RouteObject[] = [
  {
    path: RoutePaths.Home,
    lazy: createLoader(RoutePaths.Home, async () => {
      const { HomePage } = await import("../pages/HomePage/Page.tsx");
      return HomePage;
    }),
  },
  {
    path: RoutePaths.Onboarding,
    lazy: createLoader(RoutePaths.Onboarding, async () => {
      const { OnboardingLayout } = await import(
        "../pages/Onboarding/Layout.tsx"
      );
      return OnboardingLayout;
    }),
    children: [
      {
        index: true,
        lazy: createLoader(RoutePaths.OnboardingIndex, async () => {
          const { OnboardingIndexPage } = await import(
            "../pages/Onboarding/Index/Page.tsx"
          );
          return OnboardingIndexPage;
        }),
      },
      {
        path: RoutePaths.OnboardingStep1,
        lazy: createLoader(RoutePaths.OnboardingStep1, async () => {
          const { OnboardingStep1Page } = await import(
            "../pages/Onboarding/Step1/Page.tsx"
          );
          return OnboardingStep1Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep2,
        lazy: createLoader(RoutePaths.OnboardingStep2, async () => {
          const { OnboardingStep2Page } = await import(
            "../pages/Onboarding/Step2/Page.tsx"
          );
          return OnboardingStep2Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep3,
        lazy: createLoader(RoutePaths.OnboardingStep3, async () => {
          const { OnboardingStep3Page } = await import(
            "../pages/Onboarding/Step3/Page.tsx"
          );
          return OnboardingStep3Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep4,
        lazy: createLoader(RoutePaths.OnboardingStep4, async () => {
          const { OnboardingStep4Page } = await import(
            "../pages/Onboarding/Step4/Page.tsx"
          );
          return OnboardingStep4Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep5,
        lazy: createLoader(RoutePaths.OnboardingStep5, async () => {
          const { OnboardingStep5Page } = await import(
            "../pages/Onboarding/Step5/Page.tsx"
          );
          return OnboardingStep5Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep6,
        lazy: createLoader(RoutePaths.OnboardingStep6, async () => {
          const { OnboardingStep6Page } = await import(
            "../pages/Onboarding/Step6/Page.tsx"
          );
          return OnboardingStep6Page;
        }),
      },
      {
        path: RoutePaths.OnboardingStep7,
        lazy: createLoader(RoutePaths.OnboardingStep7, async () => {
          const { OnboardingStep7Page } = await import(
            "../pages/Onboarding/Step7/Page.tsx"
          );
          return OnboardingStep7Page;
        }),
      },
    ],
  },
  {
    path: RoutePaths.Game,
    lazy: createLoader(RoutePaths.Game, async () => {
      const { GameLayout } = await import("../pages/GamePage/Layout.tsx");
      return GameLayout;
    }),
    children: [
      {
        index: true,
        lazy: createLoader(RoutePaths.GameIndex, async () => {
          const { GameClickerPage } = await import(
            "../pages/GamePage/ClickerPage/Page.tsx"
          );
          return GameClickerPage;
        }),
      },
      {
        path: RoutePaths.GameCityBuilder,
        lazy: createLoader(RoutePaths.GameCityBuilder, async () => {
          const { GameCityBuilderPage } = await import(
            "../pages/GamePage/CityBuilderPage/Page.tsx"
          );
          return GameCityBuilderPage;
        }),
      },
      {
        path: RoutePaths.GameMoves,
        lazy: createLoader(RoutePaths.GameMoves, async () => {
          const { MovesPage } = await import(
            "../pages/GamePage/MovesPage/Page.tsx"
          );
          return MovesPage;
        }),
      },
      {
        path: RoutePaths.GameMovesSingle,
        lazy: createLoader(RoutePaths.GameMovesSingle, async () => {
          const { SingleMove } = await import(
            "../pages/GamePage/MovesPage/subpages/SingleMovePage.tsx"
          );
          return SingleMove;
        }),
      },
      {
        path: RoutePaths.GameMovesClaimable,
        lazy: createLoader(RoutePaths.GameMovesClaimable, async () => {
          const { ClaimableMovesPage } = await import(
            "../pages/GamePage/MovesPage/subpages/ClaimableMovesPage.tsx"
          );
          return ClaimableMovesPage;
        }),
      },
      {
        path: RoutePaths.GameChamps,
        lazy: createLoader(RoutePaths.GameChamps, async () => {
          const { ChampsPage } = await import(
            "../pages/GamePage/ChampsPage/Page.tsx"
          );
          return ChampsPage;
        }),
      },
      {
        path: RoutePaths.GameEarn,
        lazy: createLoader(RoutePaths.GameEarn, async () => {
          const { EarnPage } = await import(
            "../pages/GamePage/EarnPage/Page.tsx"
          );
          return EarnPage;
        }),
      },
      {
        path: RoutePaths.GameAirdrop,
        lazy: createLoader(RoutePaths.GameAirdrop, async () => {
          const { AirdropPage } = await import(
            "../pages/GamePage/AirdropPage/Page.tsx"
          );
          return AirdropPage;
        }),
      },
      {
        path: RoutePaths.GameLottery,
        lazy: createLoader(RoutePaths.GameLottery, async () => {
          const { LotteryPage } = await import(
            "../pages/GamePage/LotteryPage/Page.tsx"
          );
          return LotteryPage;
        }),
      },
      {
        path: RoutePaths.GameSettings,
        lazy: createLoader(RoutePaths.GameSettings, async () => {
          const { SettingsPage } = await import(
            "../pages/GamePage/SettingsPage/Page.tsx"
          );
          return SettingsPage;
        }),
      },
      {
        path: RoutePaths.GameSettingsLanguage,
        lazy: createLoader(RoutePaths.GameSettingsLanguage, async () => {
          const { LanguagePage } = await import(
            "../pages/GamePage/SettingsPage/LanguagePage/Page.tsx"
          );
          return LanguagePage;
        }),
      },
      {
        path: RoutePaths.GameBoosters,
        lazy: createLoader(RoutePaths.GameBoosters, async () => {
          const { BoosterScreen } = await import(
            "../pages/GamePage/BoostsPage/Page.tsx"
          );
          return BoosterScreen;
        }),
      },
      {
        path: RoutePaths.GameSkins,
        lazy: createLoader(RoutePaths.GameSkins, async () => {
          const { SkinsPage } = await import(
            "../pages/GamePage/SkinsPage/Page.tsx"
          );
          return SkinsPage;
        }),
      },
    ],
  },
  {
    path: RoutePaths.NotFound,
    lazy: createLoader(RoutePaths.NotFound, async () => {
      const { NotFound } = await import("../components/errors/NotFound.tsx");
      return NotFound;
    }),
  },
];
